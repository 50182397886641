import Component from '../../js/_Component';

const CNAME = 'c-block-image-and-text-rows';

export default class BlockImageAndTextRows extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockImageAndTextRows, 'BlockImageAndTextRows');
