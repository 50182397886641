import Component from '../../js/_Component';
import BaseVideo from '../BaseVideo/_BaseVideo';

const CNAME = 'c-block-video';

export default class BlockVideo extends Component {
	constructor(element: HTMLElement) {
		super(element);

		const button = this.element.querySelector(`.${CNAME}--button`);

		if (button) {
			const video: BaseVideo = Component.getComponent(this.element.querySelector('.c-base-video'));
			button.addEventListener('click', () => {
				if (video.playVideo() !== false) {
					video.removePlaceholder();
					button.classList.add('m-hidden');
				}
			});
		}
	}
}

Component.register(BlockVideo, 'BlockVideo');
