import Component from '../../js/_Component';

const CNAME = 'c-page-about-us';

export default class PageAboutUs extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PageAboutUs, 'PageAboutUs');
