/**
 * Outputs Lorem Ipsum on lorem-type elements to help auto-fill in placeholder
 * copy. Does not accept parameters but does change output based on the
 * "type" found in the dummy element.
 *
 * This works with the general.php config file, and should never be called
 * in production.
 *
 * Lorem Type also makes the assumption that there is already some form of
 * styling/formatting being applied to the copy. It strictly outputs tags and words.
 *
 */
export default class LoremType {
	/**
	 * Finds all applicable Lorem Type elements and kicks off the replacement.
	 * @param element The element to scan for nodes within.
	 */
	static scan(element: HTMLElement) {
		// Note: It is assumed that we're dealing with spans.
		const elements = element.querySelectorAll('span.lorem-type');
		elements.forEach((node: HTMLElement) => {
			// Grab the type of content paramater
			const typeOfContent = node.dataset.type;
			this.attach(node as HTMLElement, typeOfContent as string);
		});
	}

	/**
	 * Attaches placeholder copy to the element's space
	 * @param element
	 * @param typeOfContent
	 */
	static attach(element: HTMLElement, type: string) {
		let output = ''; // This will be HTML output in place of the element

		function textNodeOutput(outputString) {
			// Replacing the span direclty with the next node
			element.replaceWith(outputString);
		}

		function elementOutput(outputString) {
			// Creating a dummy div and filling it with type related elements
			// Replace span with contents of div
			const outputDiv = document.createElement('div');
			outputDiv.innerHTML = outputString;
			Array.from(outputDiv.children).forEach((item) => {
				element.parentElement.insertBefore(item, element);
			});
			element.parentElement.removeChild(element);
		}

		switch (type) {
			case 'xsWords':
				output = this.getLoremIpsum(4);
				textNodeOutput(output);
				break;
			case 'smWords':
				output = this.getLoremIpsum(8);
				textNodeOutput(output);
				break;
			case 'mdWords':
				output = this.getLoremIpsum(12);
				textNodeOutput(output);
				break;
			case 'lgWords':
				output = this.getLoremIpsum(40);
				textNodeOutput(output);
				break;
			case 'xlWords':
				output = this.getLoremIpsum(80);
				textNodeOutput(output);
				break;
			case 'xsWysiwyg':
				output = `<p> ${this.getLoremIpsum(20)} </p>`;
				elementOutput(output);
				break;
			case 'smWysiwyg':
				output = `<p> ${this.getLoremIpsum(40)} </p>`;
				elementOutput(output);
				break;
			case 'mdWysiwyg':
				output = `<h3>${this.getLoremIpsum(4)}</h3><p> ${this.getLoremIpsum(60)} </p>`;
				elementOutput(output);
				break;
			case 'lgWysiwyg':
				output = `<h3>${this.getLoremIpsum(4)}</h3><p> ${this.getLoremIpsum(60)} </p><p> ${this.getLoremIpsum(60)} </p>`;
				elementOutput(output);
				break;
			case 'xlWysiwyg':
				output = `<h2>${this.getLoremIpsum(4)}</h2><p> ${this.getLoremIpsum(80)} </p><p> ${this.getLoremIpsum(60)} </p>`;
				elementOutput(output);
				break;
			default:
				// No specific type passed in, output nothing.
		}
	}

	/**
	 * Function to generate the actual Lorem Ipsum
	 *
	 */
	static getLoremIpsum(wordCount) {
		const wordBankSource = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam lacus suspendisse faucibus interdum posuere. Vel orci porta non pulvinar. Nulla malesuada pellentesque elit eget. Ultrices gravida dictum fusce ut placerat orci nulla. Turpis egestas sed tempus urna et pharetra pharetra. Fames ac turpis egestas sed tempus urna. Ante in nibh mauris cursus mattis molestie a iaculis. Morbi tristique senectus et netus et. Mi sit amet mauris commodo quis imperdiet massa tincidunt nunc. Habitasse platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim. Nisl pretium fusce id velit ut. Velit laoreet id donec ultrices tincidunt arcu.';

		const wordBank = wordBankSource.split(' ');

		let result = '';
		let offset = Math.floor(Math.random() * wordBank.length);

		for (let index = 0; index < wordCount; index += 1) {
			if (offset >= wordBank.length) {
				offset = 0;
			}

			if (index === 0) {
				result += `${wordBank[offset].charAt(0).toUpperCase() + wordBank[offset].slice(1)} `;
			} else {
				result += `${wordBank[offset]} `;
			}

			offset += 1;
		}

		result = result.slice(0, -1);

		if (result.slice(-1) === ',') {
			result = `${result.slice(0, -1)}.`;
		} else if (result.slice(-1) !== '.') {
			result = `${result}.`;
		}

		return result;
	}
}
