import Component from '../../js/_Component';
import Bubbles from '../../js/util/_Bubbles';
import {randBool} from '../../js/util/_random';
import shouldAnimate from '../../js/util/_shouldAnimate';

const CNAME = 'c-base-image';

export default class BaseImage extends Component {
	constructor(element: HTMLElement) {
		super(element);

		if (shouldAnimate()) {
			const bubblePosition = randBool() ? 'top-left' : 'top-right';
			Bubbles.createBubbles(this.element, bubblePosition);
		}
	}
}

Component.register(BaseImage, 'BaseImage');
