// Adds `:scope` selector support in Element functions that would normally support it for IE and Edge.
// From https://github.com/jonathantneal/element-qsa-scope

let scope = /:scope(?![\w-])/gi;

function buildFunc(original: (this: HTMLElement, x: string) => any) {

	return function(this: HTMLElement, selector: string) {
		let hasScope = scope.test(selector);

		if (hasScope) {
			// If the element does not have an ID we can already hook into.
			let hasNoId = !this.id;
			if (hasNoId) {
				this.id = 'SCOPE_POLYFILL_ID';
			}

			// Use the element's ID in place of :scope selectors.
			selector = selector.replace(scope, '#' + this.id);
			let result = original.call(this, selector);

			// Unset the manual ID.
			if (hasNoId) {
				this.id = undefined;
			}

			return result;
		} else { // Continue as normal if there are no :scope selectors.
			return original.call(this, selector);
		}
	}
}

try {
	document.querySelector(':scope body'); // Test for support, will throw if not supported.
} catch (err) {

	let elementProto = Element.prototype;

	elementProto.querySelector = buildFunc(elementProto.querySelector);
	elementProto.querySelectorAll = buildFunc(elementProto.querySelectorAll);
	elementProto.matches = buildFunc(elementProto.matches);
	elementProto.closest = buildFunc(elementProto.closest);
}
