import Component from '../../js/_Component';

const CNAME = 'c-page-career-insights';

export default class PageCareerInsights extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PageCareerInsights, 'PageCareerInsights');
