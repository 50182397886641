// Adds patchy `ResizeObserver` support for browsers.

if (!(window as any).ResizeObserver) {

	let queued = false;

	let observers: PolyfillObserver[] = [];

	let queueChangeQuery = function() {
		if (!queued) {
			queued = true;
			window.requestAnimationFrame(() => {
				queued = false;

				observers.forEach(observer => {
					observer.elementSizes.forEach((lastSize, element) => {
						let rect = element.getBoundingClientRect() as DOMRect;
						if (rect.width != lastSize.width || rect.height != lastSize.height) {
							observer.elementSizes.set(element, rect);
							observer.callback([{
								target: element,
								contentRect: rect
							}], observer);
						}
					});
				});
			});
		}
	}

	let globalObserver = new MutationObserver(queueChangeQuery);
	globalObserver.observe(document, {
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true,
	});
	window.addEventListener('transitionend', queueChangeQuery);
	window.addEventListener('resize', queueChangeQuery);

	class PolyfillObserver {

		public callback: ResizeObserverCallback;

		public elementSizes: Map<Element, DOMRect> = new Map();

		constructor(callback: ResizeObserverCallback) {
			this.callback = callback;
			observers.push(this);
		}

		public observe(target: Element) {
			let rect = this.elementSizes.get(target);
			if (!rect) {
				rect = target.getBoundingClientRect() as DOMRect;
				this.elementSizes.set(target, rect);
			}
		}

		public unobserve(target: Element) {
			this.elementSizes.delete(target);
		}

		public disconnect() {
			this.elementSizes.clear();
		}
	}

	(window as any).ResizeObserver = PolyfillObserver;
}