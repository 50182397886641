import Component from '../../js/_Component';

const CNAME = 'c-page-media';

export default class PageMedia extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PageMedia, 'PageMedia');
