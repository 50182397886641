// Adds `Element.matches`, `Element.remove`, `Element.closest`, and `Element.children` support for IE 11.

// From https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill

if (!Element.prototype.matches) {
	Element.prototype.matches = (Element.prototype as any).msMatchesSelector || (Element.prototype as any).webkitMatchesSelector;
}

if (!Element.prototype.remove) {
	Element.prototype.remove = function () {
		this.parentNode.removeChild(this);
	}
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		var el = this;

		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

if (!Element.prototype.hasOwnProperty('children')) {
	Object.defineProperty(Element.prototype, 'children', {
		get: function () {
			var i = 0, node, nodes = this.childNodes, children = [];
			while (node = nodes[i++]) {
				if (node.nodeType === 1) {
					children.push(node);
				}
			}
			return children;
		}
	});
}
