// Adds `DOMTokenList.forEach`, general `Element.classList`, and multi-argument `classList.add`/`classList.remove` support for IE 11.

if (!DOMTokenList.prototype.forEach) {
	DOMTokenList.prototype.forEach = function(callbackfn: (value: string, index: number, array) => void, thisArg?: any) {
		Array.from(thisArg || this).forEach(callbackfn, thisArg);
	};
}

if (!Object.getOwnPropertyDescriptor(Element.prototype, 'classList')) {
	if (Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'classList')) {
		Object.defineProperty(Element.prototype, 'classList', Object.getOwnPropertyDescriptor(HTMLElement.prototype, 'classList'));
	}
}

// Supplement multi-argument classlist functions if they don't work already.
let testDiv = document.createElement('div');
testDiv.classList.add('a', 'b');
if (!testDiv.classList.contains('b')) {
	let originalAdd = DOMTokenList.prototype.add;
	DOMTokenList.prototype.add = function(this: DOMTokenList, ...classes) {
		classes.forEach(item => originalAdd.call(this, item));
	};
	let originalRemove = DOMTokenList.prototype.remove;
	DOMTokenList.prototype.remove = function(this: DOMTokenList, ...classes) {
		classes.forEach(item => originalRemove.call(this, item));
	};
}
