export function randBool(): boolean {
	return Math.random() > 0.5;
}

export function randInt(min: number, max: number): number {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randFloat(min: number, max: number): number {
	return parseFloat((Math.random() * (max - min) + min).toFixed(3));
}
