import Component from '../../js/_Component';

const CNAME = 'c-base-button-primary';

export default class BaseButtonPrimary extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BaseButtonPrimary, 'BaseButtonPrimary');
