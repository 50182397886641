import ResizeClasses from './_ResizeClasses';

/**
 * Handles the grid system's responsive classes by monitoring the DOM for
 * changes and adding and removing responsive classes as necessary.
 *
 * The `.row` class receives responsive classes, as well as an `.eq-init` class,
 * which has no accompanying CSS.
 */
export default class GridObserver {
	/**
	 * Watches an element for added child elements, attaching the responsive
	 * class synchronizer behavior to appropriate elements.
	 * @param element The element to watch for modifications of.
	 */
	static monitor(element: HTMLElement) {
		// Watch the body for any newly added elements.
		const observer = new MutationObserver((records) => {
			records.forEach((record) => {
				record.addedNodes.forEach((node) => {
					if (node instanceof HTMLElement) {
						if (node.classList.contains('row') || node.classList.contains('eq')) {
							this.attach(node);
						}
						this.scan(node);
					}
				});
			});
		});
		observer.observe(element, {
			childList: true,
			subtree: true,
		});
	}

	/**
	 * Finds all applicable row elements that are below the provided element
	 * @param element The element to scan for nodes within.
	 */
	static scan(element: HTMLElement) {
		const elements = element.querySelectorAll('.row, .eq');
		elements.forEach((node) => {
			this.attach(node as HTMLElement);
		});
	}

	/**
	 * Attach the ResizeClasses behavior with the breakpoints.
	 * @param element The object element to add behavior to.
	 */
	static attach(element: HTMLElement) {
		new ResizeClasses(element, {
			'eq-init': {min: 0}, // Initialization of EQ
			'min-16rem': {min: 256}, // Special
			'min-20rem': {min: 320}, // Special
			'min-32rem': {min: 512}, // SM
			'min-48rem': {min: 768}, // MD
			'min-64rem': {min: 1024}, // LG
			'min-80rem': {min: 1280}, // XL
		});
	}
}
