import Component from '../../js/_Component';

const CNAME = 'c-base-text';

const phoneregex = /([\s\S]*?)\b((?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?)\b/g;

export default class BaseText extends Component {
	constructor(element: HTMLElement) {
		super(element);

		// Don't check for tel links within an existing link.
		if (!this.element.closest('a')) {
			BaseText.checkForTelephoneLinks(this.element);
		}
	}

	static checkForTelephoneLinks(element: HTMLElement) {
		Array.from(element.childNodes).forEach((node) => {
			// Check iteratively through child nodes.
			if (node.nodeType === Node.ELEMENT_NODE) {
				if ((node as Element).tagName !== 'A') {
					this.checkForTelephoneLinks(node as HTMLElement);
				}
			// Change contents of text nodes
			} else if (node.nodeType === Node.TEXT_NODE) {
				let prevIndex = 0;
				// Loop over every match, breaking only once finished.
				// eslint-disable-next-line no-constant-condition
				while (true) {
					// Find a match.
					const match = phoneregex.exec(node.textContent);
					if (!match) {
						// No match was found, create a new text node using the remaining text from
						// the end of the last match to the end of the content.
						const string = node.textContent.substring(prevIndex);
						const textNode = document.createTextNode(string);
						element.insertBefore(textNode, node);
						break;
					}
					// Keep track of the position just after the end of the match.
					prevIndex = phoneregex.lastIndex;
					// Create a text node containing the preceeding content.
					element.insertBefore(document.createTextNode(match[1]), node);
					// Create a break to separate the link from the rest of the text.
					element.insertBefore(document.createElement('wbr'), node);
					// Create an anchor, filling its href with the parsed number and its content
					// with the original matched block.
					const anchor = document.createElement('a');
					anchor.href = `tel://${match[3] || ''}${match[4] || ''}${match[5] || ''}${match[6] || ''}${match[7] || ''}`;
					anchor.textContent = match[2];
					element.insertBefore(anchor, node);
					// Create a break to separate the link from the rest of the text.
					element.insertBefore(document.createElement('wbr'), node);
				}
				// Remove the original text node.
				element.removeChild(node);
			}
		});
	}
}

Component.register(BaseText, 'BaseText');
