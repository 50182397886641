import {randInt, randFloat} from './_random';

const ANIMATION_VARIATIONS = 10;

/**
 * Manages the creation of localized bubble streams.
 */
export default class Bubbles {
	/**
	 * Creates a number of randomly-generated variations of the float animation for global use.
	 * When bubbles are created, they are assigned an animation at random.
	 */
	static init(): void {
		const style = document.createElement('style');

		for (let i = 1; i <= ANIMATION_VARIATIONS; i += 1) {
			style.innerHTML += `@keyframes bubble-${i} {
				0% {transform: scale(0) translate(0, 0)}
				25% {transform: scale(.4) translate(${randInt(-8, 8)}px, -${i * 20 + randInt(0, 5)}px)}
				50% {transform: scale(.7) translate(${randInt(-8, 8)}px, -${i * 40 + randInt(0, 5)}px)}
				75% {transform: scale(.9) translate(${randInt(-8, 8)}px, -${i * 60 + randInt(0, 5)}px)}
				100% {transform: scale(1) translate(${randInt(-8, 8)}px, -${i * 80 + randInt(0, 5)}px)}
			}`;
		}

		document.head.appendChild(style);
	}

	/**
	 * Creates a random number of bubbles and appends the container to the provided element.
	 */
	static createBubbles(parent: Element, position: string): void {
		const bubbles = document.createElement('div');
		bubbles.classList.add('bubbles');
		bubbles.classList.add(`m-${position}`);

		for (let i = 1; i <= randInt(5, 9); i += 1) {
			bubbles.appendChild(Bubbles.createBubble());
		}

		parent.appendChild(bubbles);

		window.requestAnimationFrame(() => {
			bubbles.style.display = 'block';
		});
	}

	/**
	 * Creates a bubble with random properties.
	 */
	static createBubble(): HTMLElement {
		const scale = randFloat(0.25, 1);
		const x = randInt(-16, 16);
		const y = randInt(-16, 16);
		const lifespan = randFloat(6, 8);
		const delay = randFloat(-lifespan * 2, -lifespan);
		const opacity = randFloat(0.25, .75);
		const animationType = randInt(1, ANIMATION_VARIATIONS);

		const bubble = document.createElement('div');
		bubble.classList.add('bubbles--bubble');
		bubble.style.animationName = `bubble-${animationType}`;
		bubble.style.animationDuration = `${lifespan}s`;
		bubble.style.animationDelay = `${delay}s`;

		const bubbleImage = document.createElement('div');
		bubbleImage.classList.add('bubbles--bubble-image');
		bubbleImage.style.transform = `scale(${scale}) translate(${x}px, ${y}px)`;
		bubbleImage.style.opacity = `${opacity}`;

		bubble.appendChild(bubbleImage);
		return bubble;
	}
}
