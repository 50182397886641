import Component from '../../js/_Component';
import shouldAnimate from '../../js/util/_shouldAnimate';

const CNAME = 'c-block-ocean';

/**
 * Maps one range of numbers to another.
 */
function map(input, inMin, inMax, outMin, outMax) {
	return Math.min(outMax, Math.max(outMin, (input - inMin) * (outMax - outMin) / (inMax - inMin) + outMin));
}

export default class BlockOcean extends Component {
	private oceanVideo: HTMLVideoElement;

	private videoContainer: HTMLElement;

	private video: HTMLVideoElement;

	private videoPlayButton: HTMLButtonElement;

	private videoCloseButton: HTMLButtonElement;

	private textWrapper: HTMLElement;

	private newTextPaddingTop: number;

	private textOpacityQueued: boolean = false;

	constructor(element: HTMLElement) {
		super(element);

		// Grab a reference to all elements.
		this.oceanVideo = document.querySelector(`.${CNAME}--ocean-video`);
		this.videoContainer = this.element.querySelector(`.${CNAME}--video-container`);
		this.video = this.element.querySelector(`.${CNAME}--video`);
		this.videoPlayButton = this.element.querySelector(`.${CNAME}--video-play-button`);
		this.videoCloseButton = this.element.querySelector(`.${CNAME}--video-close-button`);
		this.textWrapper = this.element.querySelector(`.${CNAME}--text-wrapper`);

		if (shouldAnimate()) {
			this.setupOceanVideo();
		}

		// After layout settles, vertically center the text wrapper and fade it in.
		this.element.addEventListener('resizeclassesapplied', () => {
			this.setTextPosition();
			window.addEventListener('resize', () => this.setTextPosition());

			// After fading in, remove transition that way the scroll-opacity effect is instant.
			this.textWrapper.addEventListener('transitionend', () => {
				this.textWrapper.style.transition = 'none';
			});

			this.setTextOpacity();
			this.textWrapper.classList.add('m-visible');
		});
		this.setTextPosition();

		window.addEventListener('scroll', () => this.queueTextOpacity(), {passive: true});

		// Only attach video behavior if the video container exists.
		if (this.videoContainer !== null) {
			this.setupVideo();
		}
	}

	setupOceanVideo() {
		this.oceanVideo.play();

		// When the ocean video is out of view, pause it.
		const observer = new IntersectionObserver((entries) => {
			entries[0].isIntersecting ? this.oceanVideo.play() : this.oceanVideo.pause();
		});
		observer.observe(this.element);
	}

	setupVideo() {
		// When the play button is clicked, play the video and then show it.
		this.videoPlayButton.addEventListener('click', () => {
			this.video.play();
			this.videoContainer.classList.add('m-visible');
			document.body.classList.add('m-hide-menu-button');
		});

		// When the close button is clicked, hide the video and then pause it once it's done fading.
		this.videoCloseButton.addEventListener('click', () => {
			this.videoCloseButton.addEventListener('transitionend', () => {
				this.video.pause();
			}, {once: true});

			this.videoContainer.classList.remove('m-visible');
			document.body.classList.remove('m-hide-menu-button');
		});
	}

	/**
	 * Vertically centers text using paddingTop so that its sticky positioning will keep it an equal
	 * distance from the top of the screen (until the bottom of this element reaches the bottom of
	 * its container.
	 */
	setTextPosition() {
		const oceanVideoHeight = this.oceanVideo.offsetHeight;
		const textStyle = window.getComputedStyle(this.textWrapper);
		const textHeight = Number(textStyle.height.replace('px', ''));
		const textPaddingTop = Number(textStyle.paddingTop.replace('px', ''));
		const textHeightPaddingless = textHeight - textPaddingTop;

		this.newTextPaddingTop = (oceanVideoHeight / 2) - (textHeightPaddingless / 2);
		this.textWrapper.style.paddingTop = `${this.newTextPaddingTop}px`;
	}

	queueTextOpacity() {
		if (!this.textOpacityQueued) {
			this.textOpacityQueued = true;
			requestAnimationFrame(() => this.setTextOpacity());
		}
	}

	/**
	 * Adds an effect where the text fades out as you scroll down.
	 */
	setTextOpacity() {
		this.textOpacityQueued = false;

		const scrollDistance = window.pageYOffset;

		const textTopPosition = this.newTextPaddingTop;

		const textBottomPosition = this.newTextPaddingTop * 2;

		// Get the percentage that the text has traveled (0 to 1).
		const progress = map(scrollDistance, textTopPosition, textBottomPosition, 0, 1);
		console.log( progress );
		if(isNaN(progress)) {
			const progress = 1;
		}
		if (progress >= 1) {
			this.textWrapper.style.setProperty('display', 'none');
			this.textWrapper.classList.remove("text-fade-in");
			this.textWrapper.style.setProperty('opacity', '1');
		} else {
			this.textWrapper.style.setProperty('display', '');
			this.textWrapper.style.setProperty('opacity', `${1 - progress}`);
			this.textWrapper.classList.add("text-fade-in");
		}
	}
}

Component.register(BlockOcean, 'BlockOcean');
