// Entry point for site JS, import all other scripts.

// Polyfills
import './polyfills/_ObjectValuesPolyfill';
import './polyfills/_MathPolyfill';
import './polyfills/_ArrayFromPolyfill';
import './polyfills/_ElementFunctionsPolyfill';
import './polyfills/_ScopePseudoSelectorPolyfill';
import './polyfills/_DocumentFragmentFunctionsPolyfill';
import './polyfills/_NodeListForEachPolyfill';
import './polyfills/_ClassListPolyfill';
import './polyfills/_EventObjectPolyfill';
import './polyfills/_StringRepeatPolyfill';
import './polyfills/_PromisePolyfill';
import './polyfills/_FetchPolyfill';
import './polyfills/_TemplatePolyfill';
import './polyfills/_ResizeObserverPolyfill';
import './polyfills/_IntersectionObserverPolyfill';
import './polyfills/_URLPolyfill';
import './polyfills/_URLSearchParamsPolyfill';
import './polyfills/_FocusVisible';
import './polyfills/_ClosestPolyfill';
import './polyfills/_ArrayIncludesPolyfill';

// Libraries
import './lib/_lazysizes.bgset';
import './lib/_lazysizes';

// Components
import '../components/BaseAnimatedBorder/_BaseAnimatedBorder';
import '../components/BaseBackgroundImage/_BaseBackgroundImage';
import '../components/BaseButtonHamburger/_BaseButtonHamburger';
import '../components/BaseButtonPrimary/_BaseButtonPrimary';
import '../components/BaseImage/_BaseImage';
import '../components/BaseLayoutStandard/_BaseLayoutStandard';
import '../components/BaseLayoutJobApplicationForm/_BaseLayoutJobApplicationForm';
import '../components/BaseSocialIcon/_BaseSocialIcon';
import '../components/BaseSVG/_BaseSVG';
import '../components/BaseTabs/_BaseTabs';
import '../components/BaseText/_BaseText';
import '../components/BaseVideo/_BaseVideo';
import '../components/BlockBackToOverviewButton/_BlockBackToOverviewButton';
import '../components/BlockBios/_BlockBios';
import '../components/BlockButton/_BlockButton';
import '../components/BlockDividerHorizontal/_BlockDividerHorizontal';
import '../components/BlockFooterCompact/_BlockFooterCompact';
import '../components/BlockHeader/_BlockHeader';
import '../components/BlockHoveringObjects/_BlockHoveringObjects';
import '../components/BlockImage/_BlockImage';
import '../components/BlockImageAndText/_BlockImageAndText';
import '../components/BlockImageAndTextRows/_BlockImageAndTextRows';
import '../components/BlockImageAndTextTabs/_BlockImageAndTextTabs';
import '../components/BlockImagesAndTextGrid/_BlockImagesAndTextGrid';
import '../components/BlockTestimonialsCarousel/_BlockTestimonialsCarousel';
import '../components/BlockOcean/_BlockOcean';
import '../components/BlockPageControls/_BlockPageControls';
import '../components/BlockParticles/_BlockParticles';
import '../components/BlockRebar/_BlockRebar';
import '../components/BlockTextCentered/_BlockTextCentered';
import '../components/BlockVideo/_BlockVideo';
import '../components/CardCareerInsightsEntry/_CardCareerInsightsEntry';
import '../components/CardConservationEntry/_CardConservationEntry';
import '../components/CardMediaEntry/_CardMediaEntry';
import '../components/PageAboutUs/_PageAboutUs';
import '../components/PageBio/_PageBio';
import '../components/PageCareerInsights/_PageCareerInsights';
import '../components/PageCareerInsightsEntry/_PageCareerInsightsEntry';
import '../components/PageCareers/_PageCareers';
import '../components/PageConservation/_PageConservation';
import '../components/PageConservationEntry/_PageConservationEntry';
import '../components/PageHomepage/_PageHomepage';
import '../components/PageMedia/_PageMedia';
import '../components/PageMediaEntry/_PageMediaEntry';
import '../components/PagePage/_PagePage';
// AUTO-INJECT-HERE - Don't edit or touch this comment unless you know what you're doing.

import Component from './_Component'; // Get component initialization functions.
import SVGResizeFixForIE from './util/_SVGResizeFixForIE'; // Manually resize marked SVGs that have broken scaling in IE.
import GridObserver from './util/_GridObserver'; // Manage grid classes.
import ExternalLinkProcessor from './util/_ExternalLinkProcessor'; // Adds attributes to external links.
import IS_IE from './util/_IEBrowserClass'; // Add `ie` class to root for IE.
import './util/_IELazyLoadBypass'; // Handle lazyloading for IE, which doesn't support `srcset` attributes.
import './util/_SafariBrowserClass'; // Add `safari` class to root for Safari.
import ScrollEnforcer from './util/_ScrollEnforcer'; // Try to prevent horizontal slack.
import LoremType from './util/_LoremType'; // Will replace dummy elements on development with generated type
import AdminModuleControls from './util/_AdminModuleControls'; // Attaches toggleable UI for module information.
import Bubbles from './util/_Bubbles';
import AnimationApplier from './util/_AnimationApplier'; // Add classes to visible, animatable elements.

function init() {
	ScrollEnforcer.preventHorizontal(document.body);

	SVGResizeFixForIE.attach();

	// Initialize all components on existing page and then monitor for changes.
	Component.scan(document.body);
	Component.monitor(document.body);

	// Initialize grid by looking for all row elements on the page.
	GridObserver.scan(document.body);
	GridObserver.monitor(document.body);

	// Watch animatable components for intersection.
	AnimationApplier.scan(document.body);
	AnimationApplier.monitor(document.body);

	// Initialize the admin module controls.
	new AdminModuleControls();

	// Initialize LoremType replacement of dummy elements.
	LoremType.scan(document.body);

	/** Processes all external links (add attributes). */
	function processExternalLinks() {
		// Get the list of pseudo external links from the meta tag (populated from a global field)
		const meta: HTMLMetaElement = document.querySelector('meta[name="pseudo-external-links"]');

		// Format the array and pass it in to the processor as a list of links to treat as external
		const pseudoExternalLinks: string[] = meta ? meta.content.split(',') : [];
		const externalLinkProcessor = new ExternalLinkProcessor(pseudoExternalLinks);

		// Scan the document for links immediately whenever a mutation occurs
		externalLinkProcessor.scan(document.body);
		externalLinkProcessor.monitor(document.body);
	}

	processExternalLinks();

	document.body.classList.remove('content-loading');

	Bubbles.init();
}

if (IS_IE) {
	// Wait for a repaint to initialize in order to allow polyfill overrides to be available in IE.
	requestAnimationFrame(init);
} else {
	// Initialize ASAP.
	init();
}
