import IS_IE from './_IEBrowserClass';

function imageLazyLoaded(e) {
	try {
		// IE doesn't support srcset, but lazysizes sets that explicitly.
		// This tries to grab the first (largest) image size from the data set
		// and apply it to the image that triggered the lazyload event.
		var sizes: string;
		if (e.target.dataset.srcset) { // Is an image element.
			sizes = e.target.dataset.srcset;
		} else { // No srcset on the image itself, it is a background image.
			sizes = e.target.parentElement.parentElement.dataset.bgset;
		}

		var src = sizes.trim().split(' ')[0]
		if (e.target.src) { // Is an image element.
			e.target.src = src;
		} else { // Is a background image.
			e.target.parentElement.parentElement.style.backgroundImage = `url('${src}')`;
		}
	} catch {} // Couldn't parse or set, oh well.
}

if (IS_IE) {
	document.body.addEventListener('lazyloaded', imageLazyLoaded);
}
