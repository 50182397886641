// Adds patchy `IntersectionObserver` support for IE 11 and older versions of Edge.

if (!(window as any).IntersectionObserver) {

	interface IntersectionObserverEntryInit_ {
		boundingClientRect: DOMRect | ClientRect;
		intersectionRatio: number;
		intersectionRect: DOMRect | ClientRect;
		rootBounds: DOMRect | ClientRect;
		target: Element;
		time: number;
	}

	class IntersectionObserverEntry_ {

		public boundingClientRect: DOMRect | ClientRect;

		public intersectionRatio: number;

		public intersectionRect: DOMRect | ClientRect;

		public rootBounds: DOMRect | ClientRect;

		public target: Element;

		public time: number;

		constructor(init: IntersectionObserverEntryInit_) {
			this.boundingClientRect = init.boundingClientRect;
			this.intersectionRatio = init.intersectionRatio;
			this.intersectionRect = init.intersectionRect;
			this.rootBounds = init.rootBounds;
			this.target = init.target;
			this.time = init.time;
		}
	};

	class IntersectionObserver_ {

		public intersections: Map<Element, number> = new Map();

		public thresholds: number[] = [];

		public rootMargin: string;

		public callback: IntersectionObserverCallback;

		private margins: {amount: number, percent: boolean}[];

		constructor(callback: IntersectionObserverCallback, options: IntersectionObserverInit = {}) {
			observers.push(this);
			this.thresholds = !options.threshold
				? [0]
				: (
					Array.isArray(options.threshold)
					? options.threshold.sort()
					: [options.threshold]
				);
			if (options.root) {
				console.warn('Non-document root not supported');
			}
			this.rootMargin = options.rootMargin || '0px 0px 0px 0px';
			this.margins = this.rootMargin.split(/s+/).map(item => {
				return {
					amount: parseFloat(item),
					percent: item.substring(item.length - 1) === '%',
				};
			});

			this.margins[1] = this.margins[1] || this.margins[0];
			this.margins[2] = this.margins[2] || this.margins[0];
			this.margins[3] = this.margins[3] || this.margins[1];

			this.callback = callback;
		}

		observe(element: Element) {
			if (!this.intersections.has(element)) {
				this.intersections.set(element, -1); // Treat as no threshold hit initially.
				queueChangeQuery(); // Queue a check for intersections.
			}
		}

		unobserve(element: Element) {
			this.intersections.delete(element);
		}

		disconnect() {
			this.intersections.clear();
		}

		takeRecords() {
			console.warn('takeRecords() not implemented');
			return [];
		}
	};

	(window as any).IntersectionObserver = IntersectionObserver_;
	(window as any).IntersectionObserverEntry = IntersectionObserverEntry_;

	let queued = false;

	let observers: IntersectionObserver_[] = [];

	let baseRect = function(width: number, height: number, left: number = 0, top: number = 0): DOMRect | ClientRect {
		return {
			x: left,
			y: top,
			width: width,
			height: height,
			left: left,
			top: top,
			right: left + width,
			bottom: top + height,
		} as DOMRect | ClientRect;
	}

	let queueChangeQuery = function() {
		if (queued) {
			return
		}
		queued = true;
		window.requestAnimationFrame(() => {
			queued = false;
			let bounds = baseRect(window.innerWidth, window.innerHeight);
			observers.forEach(observer => {
				let entries: IntersectionObserverEntry_[] = [];
				observer.intersections.forEach((oldThreshold, element) => {
					let rect = element.getBoundingClientRect();
					let intersection: DOMRect | ClientRect = null;
					let newRatio = -1; // Start at a below 0 value, to avoid matching >= 0 for zero-sized items later.
					if (rect.width == 0 || rect.height == 0) { // No size, not visible, always fail.
						intersection = baseRect(0, 0);
					} else { // Calculate the boundaries of an intersection.
						let left = Math.max(bounds.left, rect.left);
						let top = Math.max(bounds.top, rect.top);
						let right = Math.min(bounds.right, rect.right);
						let bottom = Math.min(bounds.bottom, rect.bottom);
						let width = right - left;
						let height = bottom - top;
						if (width > 0 && height > 0) { // Create intersection rect, calucate ratio.
							intersection = baseRect(width, height, left, top);
							newRatio = (width * height) / (rect.width * rect.height);
						} else { // No intersection.
							intersection = baseRect(0, 0);
						}
					}
					// Determine threshold that we meet given the intersection ratio.
					// These are in order, so each will match up to the largest.
					let newThreshold = -1;
					observer.thresholds.forEach((ratio, threshold) => {
						if (newRatio >= ratio) { // At least as big as this threshold. This is why newRatio is -1 and not 0.
							newThreshold = threshold;
						}
					});
					if (newThreshold != oldThreshold) { // Overlaps at a different threshold amount.
						entries.push(new IntersectionObserverEntry_({
							boundingClientRect: rect,
							intersectionRect: intersection,
							intersectionRatio: Math.max(newRatio, 0),
							rootBounds: bounds,
							target: element,
							time: performance.now(),
						}) as IntersectionObserverEntry);
						observer.intersections.set(element, newThreshold); // Update threshold amount.
					}
				});
				if (entries.length) {
					observer.callback(entries as IntersectionObserverEntry[], observer as any as IntersectionObserver);
				}
			});
		});
	};

	let globalObserver = new MutationObserver(queueChangeQuery);
	globalObserver.observe(document, {
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true,
	});
	window.addEventListener('transitionend', queueChangeQuery);
	window.addEventListener('resize', queueChangeQuery);
	window.addEventListener('scroll', queueChangeQuery);
}

// Fix undefined property specifically in some Edge versions.
if (!((window as any).IntersectionObserverEntry.prototype.hasOwnProperty('isIntersecting'))) {
	Object.defineProperty((window as any).IntersectionObserverEntry.prototype, 'isIntersecting', {
		get: function () {
			return this.intersectionRatio > 0;
		}
	});
}
