/**
 * Simple utility to keep elements from scrolling.
 */
export default class ScrollEnforcer {
	/**
	 * Automatically undo any horizontal scrolling.
	 * @param element The element to prevent scrolling of.
	 */
	static preventHorizontal(element: HTMLElement) {
		element.addEventListener('scroll', (e) => {
			if (element.scrollLeft) {
				e.preventDefault();
				element.scrollLeft = 0;
				return false;
			}

			return true;
		});
	}

	/**
	 * Automatically undo any scrolling.
	 * @param element The element to prevent scrolling of.
	 */
	static preventBoth(element: HTMLElement) {
		element.addEventListener('scroll', (e) => {
			let prevent = false;

			if (element.scrollLeft) {
				prevent = true;
				element.scrollLeft = 0;
			}

			if (element.scrollTop) {
				prevent = true;
				element.scrollTop = 0;
			}

			if (prevent) {
				e.preventDefault();
				return false;
			}

			return true;
		});
	}
}
