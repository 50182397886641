/** Whether the browser is Safari. */
const IS_SAFARI = navigator.userAgent.indexOf('Safari') != -1
	&& navigator.userAgent.indexOf('Chrome') == -1;

// Apply a class to the HTML element to allow for better CSS fallbacks.
if (IS_SAFARI) {
	document.documentElement.classList.add('safari');
}

export default IS_SAFARI;
