import Component from '../../js/_Component';

const CNAME = 'c-page-page';

export default class PagePage extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PagePage, 'PagePage');
