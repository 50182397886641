import Component from '../../js/_Component';

const CNAME = 'c-card-conservation-entry';

export default class CardConservationEntry extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(CardConservationEntry, 'CardConservationEntry');
