import Component from '../../js/_Component';

const CNAME = 'c-block-images-and-text-grid';

export default class BlockImagesAndTextGrid extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockImagesAndTextGrid, 'BlockImagesAndTextGrid');
