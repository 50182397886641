/**
 * Basic event management class that allows listening for and raising events.
 */
export default function EventEmitter<T extends new(...args) => {}>(Base: T) {
	return class extends Base {
		private handlers: {[event: string]: Function[]};

		/**
		 * Creates a new EventEmitter.
		 * @param args Arguments to forward to the extended class.
		 */
		constructor(...args) {
			super(...args);

			/**
			 * The event handlers currently registered with the emitter.
			 */
			this.handlers = {};
		}

		/**
		 * Begins listening for a given event.
		 * @param name The key of the event to listen for.
		 * @param func The handler to register for the event.
		 */
		on(name: string, func: Function) {
			if (!this.handlers[name]) {
				this.handlers[name] = [];
			}
			this.handlers[name].push(func);
		}

		/**
		 * Stops listening for a given event.
		 * @param name The key of the event to remove the handler from.
		 * @param func The specific handler to remove, or null to remove all.
		 */
		off(name: string, func: Function) {
			if (!this.handlers[name]) {
				return;
			}
			if (func) {
				// eslint-disable-next-line no-constant-condition
				while (true) {
					const index = this.handlers[name].indexOf(func);
					if (index < 0) {
						break;
					}
					this.handlers[name].splice(index, 1);
				}
				if (this.handlers[name].length === 0) {
					delete this.handlers[name];
				}
			} else {
				delete this.handlers[name];
			}
		}

		/**
		 * Raises an event, calling all handlers registered for it.
		 * @param name The key of the event to raise.
		 * @param args Arguments to pass to the handlers called.
		 */
		raise(name: string, ...args) {
			if (!this.handlers[name]) {
				return;
			}
			this.handlers[name].forEach((emitter) => {
				emitter(...args);
			});
		}
	};
}
