import Component from '../../js/_Component';

const CNAME = 'c-block-divider-horizontal';

export default class BlockDividerHorizontal extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockDividerHorizontal, 'BlockDividerHorizontal');
