/// <reference path="../../js/_ResizeObserver.ts" />
/// <reference path="./_vimeo.d.ts" />

import Component from '../../js/_Component';
import LoadScripts from '../../js/util/_ScriptLoader';

const CNAME = 'c-base-video';

export default class BaseVideo extends Component {
	private video: HTMLVideoElement;

	private embed: HTMLElement;

	private placeholder: HTMLElement;

	private playFunc: () => boolean | void = () => false;

	constructor(element: HTMLElement) {
		super(element);

		this.video = this.element.querySelector('video');

		this.embed = this.element.querySelector(`.${CNAME}--embed`);

		this.placeholder = this.element.querySelector(`.${CNAME}--cover`);

		if (this.video) {
			this.prepareVideo();
		} else if (this.embed) {
			this.prepareEmbed();
		}
	}

	/**
	 * Removes the placeholder image, if one exists.
	 */
	removePlaceholder() {
		if (this.placeholder) {
			this.placeholder.parentElement.removeChild(this.placeholder);
			this.placeholder = null;
		}
	}

	/**
	 * Plays the underlying video content.
	 */
	playVideo() {
		return this.playFunc();
	}

	/**
	 * Runs initialization of the component's video element.
	 */
	private prepareVideo() {
		this.playFunc = () => {this.video.play();};
		if (this.video.autoplay) {
			this.video.addEventListener('play', () => {
				this.removePlaceholder();
			});
		}
	}

	/**
	 * Runs generation and initialization of the component's embedded video
	 * content.
	 */
	private prepareEmbed() {
		let match;
		const videoUrl = this.embed.dataset.embed;

		// YouTube handler, find ID and build embed url.
		match = videoUrl.match(/^(?:https?:\/\/|\/\/)?(?:[a-z]*\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?![\w-])/);
		if (match) {
			LoadScripts('https://www.youtube.com/iframe_api')
				.then(() => {
					// Wait for the api object to be available globally before continuing.
					return new Promise((resolve) => {
						const apiWaitInterval = setInterval(() => {
							if (YT.Player) {
								clearInterval(apiWaitInterval);
								resolve();
							}
						}, 50);
					});
				})
				.then(() => {
					const embedLocation = document.createElement('div');
					this.embed.appendChild(embedLocation);
					const player = new YT.Player(embedLocation, {
						videoId: match[1],
						playerVars: {
							origin: window.location.origin,
							enablejsapi: 1,
							loop: this.embed.dataset.loop !== undefined ? 1 : 0,
							rel: 0,
							autohide: 1,
							controls: this.embed.dataset.controls !== undefined ? 2 : 0,
							autoplay: this.embed.dataset.autoplay !== undefined ? 1 : 0,
						},
						events: {
							onReady: () => {
								if (this.embed.dataset.muted !== undefined) {
									player.mute();
								}

								if (this.embed.dataset.autoplay !== undefined) {
									this.removePlaceholder();
									// In some browsers, it may prevent autoplaying when not
									// initially muted, so this attempts to play after applying
									// mute, if that was an option.
									player.playVideo();
								}

								this.playFunc = () => player.playVideo();
							},
						},
					});
				});
			return;
		}

		// Vimeo handler, find ID and build embed url.
		match = videoUrl.match(/(?:https?:\/\/)?(?:[a-z]*\.)?vimeo\.com\/[a-z/]*([0-9]{6,11}).*/);
		if (match) {
			LoadScripts('https://player.vimeo.com/api/player.js')
				.then(() => {
					// Wait for the api object to be available globally before continuing.
					return new Promise((resolve) => {
						const apiWaitInterval = setInterval(() => {
							if (Vimeo.Player) {
								clearInterval(apiWaitInterval);
								resolve();
							}
						}, 50);
					});
				})
				.then(() => {
					const player = new Vimeo.Player(this.embed, {
						id: match[1],
						loop: this.embed.dataset.loop !== undefined,
						controls: this.embed.dataset.controls !== undefined,
						autoplay: this.embed.dataset.autoplay !== undefined,
						muted: this.embed.dataset.muted !== undefined,
						width: 0,
						height: 0,
					});
					player.ready().then(() => {
						if (this.embed.dataset.autoplay !== undefined) {
							this.removePlaceholder();
						}

						this.playFunc = () => {player.play();};
					});
				});
			return;
		}

		console.warn(`Invalid embedded video url: ${videoUrl}`);
	}
}

Component.register(BaseVideo, 'BaseVideo');
