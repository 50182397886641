import Component from '../../js/_Component';
import {randInt, randFloat} from '../../js/util/_random';
import shouldAnimate from '../../js/util/_shouldAnimate';

const CNAME = 'c-block-particles';

export default class BlockParticles extends Component {
	private containerWidth: number;

	private containerHeight: number;

	constructor(element: HTMLElement) {
		super(element);

		this.containerWidth = this.element.offsetWidth;
		this.containerHeight = this.element.offsetHeight;

		// Only proceed for larger devices.
		if (shouldAnimate()) {
			this.init();
		}
	}

	init() {
		this.defineAnimation();

		// Get the number of particles to generate from the area of the container.
		const particleCount = (this.containerWidth * this.containerHeight) * 0.00002;
		const largeParticleCount = particleCount / 20;

		for (let i = 0; i < particleCount; i += 1) {
			this.createParticle('small');
		}

		for (let i = 0; i < largeParticleCount; i += 1) {
			this.createParticle('large');
		}
	}

	/**
	 * Programmatically generates the animation definition based on the container size.
	 */
	defineAnimation() {
		const style = document.createElement('style');

		style.innerHTML = `@keyframes particle-float {
			0% {transform: translate(0, ${this.containerHeight / 2}px)}
			100% {transform: translate(0, ${-this.containerHeight / 2}px)}
		}`;

		document.head.appendChild(style);
	}

	/**
	 * Creates a particle with random properties.
	 */
	createParticle(type) {
		const size = type === 'small' ? randInt(1, 4) : randInt(16, 32);
		const lifespan = randFloat(this.containerHeight / 50, this.containerHeight / 10);

		const particleOffset = document.createElement('div');
		particleOffset.classList.add(`${CNAME}--particle-offset`);
		particleOffset.style.left = `${randInt(0, 100)}%`;
		particleOffset.style.transform = `rotate(${randInt(-3, 3)}deg)`;

		const particleAnimation = document.createElement('div');
		particleAnimation.classList.add(`${CNAME}--particle-animation`);
		particleAnimation.style.width = `${size}px`;
		particleAnimation.style.height = `${size}px`;
		particleAnimation.style.animationDuration = `${lifespan}s`;
		particleAnimation.style.animationDelay = `${randFloat(-lifespan * 2, -lifespan)}s`;
		particleAnimation.style.opacity = `${randFloat(0.02, 0.2)}`; // CS Debug
		//particleAnimation.style.backgroundColor = `rgba(255,255,255,1)`; // CS Debug

		if (type === 'large') {
			particleAnimation.classList.add(`m-large`);
			particleAnimation.classList.add(`m-large-${randInt(1, 5)}`);
		}

		particleOffset.appendChild(particleAnimation);
		this.element.appendChild(particleOffset);
	}
}

Component.register(BlockParticles, 'BlockParticles');
