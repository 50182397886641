import Component from '../../js/_Component';

const CNAME = 'c-base-animated-border';

export default class BaseAnimatedBorder extends Component {
	/** The SVG element. */
	private svg: SVGElement;

	/** The element which will be animated in. */
	private border: SVGRectElement;

	/** Offset from the viewport's bounding box for the IntersectionObserver. */
	private rootMargin = '-10% 0px -10% 0px';

	/** Visibility percentage required to invoke the IntersectionObserver callback. */
	private threshold = 0;

	/** The easing function for the line animation. */
	private easing = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';

	/** The animation delay. */
	private delay = '50ms';

	/** The width of the SVG. */
	private width: number;

	/** The height of the SVG. */
	private height: number;

	/** The total perimeter of the SVG. */
	private perimeter: number;

	/** The duration of the border animation. */
	private duration: string;

	constructor(element: HTMLElement) {
		super(element);

		this.svg = this.element.querySelector(`.${CNAME}--svg`);
		this.border = this.element.querySelector(`.${CNAME}--border`);

		this.createObserver();
	}

	setAttributes() {
		const rect = this.element.getBoundingClientRect();
		this.width = rect.width;
		this.height = rect.height;

		this.perimeter = (this.width + this.height) * 2;
		this.duration = `${750 + (this.perimeter / 2)}ms`;

		this.svg.setAttribute('viewBox', `0 0 ${this.width} ${this.height}`);
		this.svg.setAttribute('width', `${this.width}`);
		this.svg.setAttribute('height', `${this.height}`);

		this.border.setAttribute('width', `${this.width}`);
		this.border.setAttribute('height', `${this.height}`);
		this.border.setAttribute('stroke-dasharray', `${this.perimeter} ${this.perimeter}`);
		this.border.setAttribute('stroke-dashoffset', `${this.perimeter}`);
	}

	createObserver() {
		/** The IntersectionObserver whose callback will trigger the line to animate. */
		const intersectionObserver = new IntersectionObserver((entries, observer) => {
			// Only continue if the item is in the viewport
			if (entries[0].intersectionRatio <= 0) return;

			// Animate the line
			window.requestAnimationFrame(() => {
				window.requestAnimationFrame(() => {
					this.setAttributes();
					this.animate();
				});
			});

			// Stop observing the element
			observer.unobserve(entries[0].target);
		}, {rootMargin: this.rootMargin, threshold: this.threshold});

		// Start observing the element
		intersectionObserver.observe(this.element);
	}

	animate() {
		// Once the animation is complete, silently switch to a border that scales automatically.
		this.border.addEventListener('transitionend', () => {
			this.element.classList.add('m-animation-complete');
		}, {once: true});

		// Trigger the animation.
		window.requestAnimationFrame(() => {
			window.requestAnimationFrame(() => {
				this.border.style.transition = `stroke-dashoffset ${this.duration} ${this.easing} ${this.delay}`;
				this.border.style.strokeDashoffset = '0';
			});
		});
	}
}

Component.register(BaseAnimatedBorder, 'BaseAnimatedBorder');
