import Component from '../../js/_Component';

const CNAME = 'c-base-layout-standard';

export default class BaseLayoutStandard extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BaseLayoutStandard, 'BaseLayoutStandard');
