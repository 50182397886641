import Component from '../../js/_Component';

const CNAME = 'c-block-image-and-text-tabs';

export default class BlockImageAndTextTabs extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockImageAndTextTabs, 'BlockImageAndTextTabs');
