import Component from '../../js/_Component';

const CNAME = 'c-block-rebar';

export default class BlockRebar extends Component {
	constructor(element: HTMLElement) {
		super(element);
		this.configureAdminControls();
	}

	configureAdminControls() {
		// Only continue if the edit URL exists.
		if (document.body.getAttribute('data-entry-edit-url') === null) return;

		// Get module meta data.
		const moduleMeta = this.element.querySelector(`.${CNAME}--meta`);
		const entryEditUrl = document.body.getAttribute('data-entry-edit-url');
		const blockType = moduleMeta.getAttribute('data-block-type');
		const blockId = moduleMeta.getAttribute('data-block-id');

		// Create Block Title
		const blockTitle = document.createElement('div');
		blockTitle.classList.add(`${CNAME}--meta-title`);
		blockTitle.innerHTML = blockType;
		moduleMeta.appendChild(blockTitle);

		// Create Block Link
		const blockAnchor = document.createElement('a');
		const blockEditUrl = new URL(entryEditUrl);
		blockEditUrl.searchParams.append('block-id', blockId);
		blockAnchor.setAttribute('href', blockEditUrl.toString());
		blockAnchor.classList.add(`${CNAME}--meta-link`);
		blockAnchor.target = '_blank';
		blockAnchor.innerHTML = 'Edit';
		moduleMeta.appendChild(blockAnchor);
	}
}

Component.register(BlockRebar, 'BlockRebar');
