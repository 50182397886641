import Component from '../../js/_Component';

const CNAME = 'c-block-image-and-text';

export default class BlockImageAndText extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockImageAndText, 'BlockImageAndText');
