import Component from '../../js/_Component';

const CNAME = 'c-page-conservation-entry';

export default class PageConservationEntry extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PageConservationEntry, 'PageConservationEntry');
