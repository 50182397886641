import Component from '../../js/_Component';

const CNAME = 'c-page-homepage';

export default class PageHomepage extends Component {
	private textKeywords: HTMLVideoElement;


	constructor(element: HTMLElement) {
		super(element);

		this.Section1AnimateText();
		this.Section2AnimateText();
		this.Section3AnimateText();
	}

	Section1AnimateText(){
		var ticker = document.getElementsByClassName("keyword1");
		var ticker_length = ticker.length;

		ticker[0].classList.add('text-slide-in');
		ticker[0].classList.add('active');

		setTimeout(function(){
			var x = 1;
			
		    setInterval(function() {

		    	[].forEach.call(ticker, function(el) {
				    el.classList.remove("text-slide-in");
				    el.classList.remove("active");
				});

			    if (x < ticker.length ) {
			       	ticker[x].classList.add('text-slide-in');
					ticker[x].classList.add('active');
			    }

			    if( x >= ticker_length ){
			    	x = 0;

			    	ticker[0].classList.add('text-slide-in');
					ticker[0].classList.add('active');

			    }

			    x++;
			}, 7000);
		},300);
	}

	Section2AnimateText(){
		var ticker = document.getElementsByClassName("keyword2");
		var ticker_length = ticker.length;

		ticker[0].classList.add('text-slide-in');
		ticker[0].classList.add('active');

		setTimeout(function(){
			var x = 1;
			
		    setInterval(function() {

		    	[].forEach.call(ticker, function(el) {
				    el.classList.remove("text-slide-in");
				    el.classList.remove("active");
				});

			    if (x < ticker.length ) {
			       	ticker[x].classList.add('text-slide-in');
					ticker[x].classList.add('active');
			    }

			    if( x >= ticker_length ){
			    	x = 0;

			    	ticker[0].classList.add('text-slide-in');
					ticker[0].classList.add('active');

			    }

			    x++;
			}, 7000);
		},300);
	}

	Section3AnimateText(){
		var ticker = document.getElementsByClassName("keyword3");
		var ticker_length = ticker.length;

		ticker[0].classList.add('text-slide-in');
		ticker[0].classList.add('active');

		setTimeout(function(){
			var x = 1;
			
		    setInterval(function() {

		    	[].forEach.call(ticker, function(el) {
				    el.classList.remove("text-slide-in");
				    el.classList.remove("active");
				});

			    if (x < ticker.length ) {
			       	ticker[x].classList.add('text-slide-in');
					ticker[x].classList.add('active');
			    }

			    if( x >= ticker_length ){
			    	x = 0;

			    	ticker[0].classList.add('text-slide-in');
					ticker[0].classList.add('active');

			    }

			    x++;
			}, 7000);
		},300);
	}
}

Component.register(PageHomepage, 'PageHomepage');
