import Component from '../../js/_Component';

const CNAME = 'c-base-social-icon';

export default class BaseSocialIcon extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BaseSocialIcon, 'BaseSocialIcon');
