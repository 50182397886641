export default class AdminModuleControls {
	private waitingForKey = false;

	private showModuleMetaClass = 'show-module-meta';

	constructor() {
		// Toggle the class on 'b' double press.
		document.addEventListener('keydown', (event) => {
			if (event.key !== 'b') return;

			if (this.waitingForKey) {
				this.waitingForKey = false;
				document.body.classList.toggle(this.showModuleMetaClass);
			} else {
				this.waitingForKey = true;
				setTimeout(() => {
					this.waitingForKey = false;
				}, 300);
			}
		});
	}
}
