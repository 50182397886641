/// <reference path="../_ResizeObserver.ts" />
// Declarations for the option object that can be passed to `ResizeClasses`.

interface ClassMinOption {
	min: number;
	max?: number;
}

interface ClassMaxOption {
	min?: number;
	max: number;
}

type ClassSizeOption = ClassMinOption | ClassMaxOption;

/**
 * A configuration of classes, described by minimum and/or maximum sizes, that
 * are to be applied via the `ResizeClasses` object. Minimum is inclusive,
 * maxiumum is exclusive.
 */
interface ClassOption {
	[key: string]: ClassSizeOption;
}

/**
 * An internal description of a breakpoint, indicating the size at which it
 * starts, and the classes that should be added/removed once hit.
 */
interface Breakpoint {
	size: number;
	add: string[];
	remove: string[];
}

/**
 * Uses a ResizeObserver when possible, otherwise falls back to a more naive
 * way of dealing with resizing, involving detecting DOM mutations and handling
 * updates via the requestAnimationFrame function.
 */
export default class ResizeClasses {
	/**
	 * The internal resize observer used to fire update events.
	 */
	private observer: ResizeObserver = null;

	/**
	 * Set to true once a resize event occurs that will cause new classes to be
	 * applied on the next repaint.
	 */
	private markedForUpdate: boolean = false;

	/**
	 * The element being observed, and which receives the classes at each
	 * breakpoint.
	 */
	private element: Element = null;

	/**
	 * A set of all breakpoints, in size order.
	 */
	private breakpoints: Breakpoint[] = [{
		size: 0,
		add: [],
		remove: [],
	}];

	/**
	 * The breakpoint that was used on the last resize that occurred, used to
	 * skip updating classes and firing application events.
	 */
	private lastBreakpointIndex: number = -1;

	/**
	 * Adds a resize monitor to the provided element, adding/removing classes
	 * when it reaches certain breakpoints.
	 * @param element The element to apply classes to when resized.
	 * @param options Describes what classes are applied and when.
	 */
	constructor(element: Element, options: ClassOption) {
		this.element = element;

		// Create breakpoints from provided options.
		Object.keys(options).forEach((optionClass) => {
			// If no minimum provided, it applies to everything below the maximum.
			const min = options[optionClass].min || 0;
			// If no maximum provided, it applies to everything above the minimum.
			const max = options[optionClass].max || null;

			let i: number;

			// Find where the new breakpoint should be inserted.
			for (i = 0; i < this.breakpoints.length; i += 1) {
				// Matches an existing breakpoint exactly, use that breakpoint.
				if (this.breakpoints[i].size === min) {
					break;
				// Found the next larger breakpoint without one that matches, insert the new one before.
				} else if (this.breakpoints[i].size > min) {
					this.breakpoints.splice(i, 0, {
						size: min,
						add: this.breakpoints[i - 1].add.slice(),
						remove: this.breakpoints[i - 1].remove.slice(),
					});
					break;
				}
			}

			// Minimum was higher than the highest breakpoint, add a new
			// breakpoint to the end.
			if (i === this.breakpoints.length) {
				this.breakpoints.push({
					size: min,
					add: this.breakpoints[i - 1].add.slice(),
					remove: this.breakpoints[i - 1].remove.slice(),
				});
			}

			// For every breakpoint at the minimum, up to the maximum (or
			// all the rest, if no maximum is provided), add the option's
			// class.
			while (i < this.breakpoints.length && ((!max) || (this.breakpoints[i].size < max))) {
				this.breakpoints[i].add.push(optionClass);
				i += 1;
			}

			// If there is a maximum, and the exceeding breakpoint doesn't
			// match it, create a new breakpoint at that spot, and ensure
			// the just-added class doesn't exist in this new breakpoint.
			if (max && ((i === this.breakpoints.length) || (this.breakpoints[i].size !== max))) {
				this.breakpoints.splice(i, 0, {
					size: max,
					add: this.breakpoints[i - 1].add.slice(0, -1),
					remove: this.breakpoints[i - 1].remove.slice(),
				});
			}

			// Add the option's class to the `remove` list for every
			// breakpoint that doesn't add it.
			for (let j = 0; j < this.breakpoints.length; j += 1) {
				if (this.breakpoints[j].add.indexOf(optionClass) < 0) {
					this.breakpoints[j].remove.push(optionClass);
				}
			}
		});

		this.observer = new ResizeObserver(() => this.changed());
		this.observer.observe(element);

		// Apply first classes immediately.
		this.changed();
	}

	private changed() {
		this.element.dispatchEvent(new CustomEvent('resizeclasseschanged'));

		if (!this.markedForUpdate) {
			window.requestAnimationFrame(() => this.applyClasses());
			this.markedForUpdate = true;
		}
	}

	private applyClasses() {
		this.markedForUpdate = false;

		// Calculate the element's size.
		const rect = this.element.getBoundingClientRect();
		const width = rect.right - rect.left;

		let newBreakpointIndex: number;

		// Find the breakpoint based on the element's size.
		for (
			newBreakpointIndex = 0;
			newBreakpointIndex < this.breakpoints.length;
			newBreakpointIndex += 1
		) {
			if (this.breakpoints[newBreakpointIndex].size > width) {
				break;
			}
		}

		// Skip updating if the new breakpoint is the same as the old.
		if (this.lastBreakpointIndex === newBreakpointIndex) {
			return;
		}
		this.lastBreakpointIndex = newBreakpointIndex;

		// Remove/add classes based on the breakpoint.
		const breakpoint = this.breakpoints[newBreakpointIndex - 1];
		breakpoint.add.forEach((item) => {
			this.element.classList.add(item);
		});
		breakpoint.remove.forEach((item) => {
			this.element.classList.remove(item);
		});

		this.element.dispatchEvent(new CustomEvent('resizeclassesapplied'));
	}
}
