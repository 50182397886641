import Component from '../../js/_Component';
import {randInt} from '../../js/util/_random';
import shouldAnimate from '../../js/util/_shouldAnimate';

const CNAME = 'c-block-hovering-objects';

interface ParallaxItem {
	element: HTMLElement;
	factor: number;
	orbitX: number;
	orbitY: number;
	orbitOffset: number;
}

/** The base orbiting area as a factor of window width, further modulated by an element's individual parallax factor. */
const ORBIT_SIZE = 0.5;

/** Maxumum visual updates per second. */
const MAX_FPS = 16;

const FRAME_INTERVAL = 1000 / MAX_FPS;

/** Global goal position depending on cursor within viewport. */
var goalX = 0;
var goalY = 0;

/** Current lagging position based on mouse within viewport. */
var currentX = 0;
var currentY = 0;

/** List of items that should hover around. */
var parallaxItems: ParallaxItem[] = [];

/** List of items that should rotate. */
var rotationItems: HTMLElement[] = [];

/** Minimum timestamp when the next render should occur. */
var nextRenderTimestamp: DOMHighResTimeStamp = 0;

window.addEventListener('mousemove', (e) => {
	goalX = e.clientX;
	goalY = e.clientY;
});

if (shouldAnimate()) {
	requestAnimationFrame(animateObjects);
}

function animateObjects(timestamp: DOMHighResTimeStamp): void {
	requestAnimationFrame(animateObjects);

	// Only continue if enough time has elapsed since the last run.
	if (timestamp < nextRenderTimestamp) return;
	nextRenderTimestamp = timestamp + FRAME_INTERVAL;

	const windowWidth = window.innerWidth;
	const time = timestamp / 1000;

	currentX = goalX * 0.1 + currentX * 0.9;
	currentY = goalY * 0.1 + currentY * 0.9;

	parallaxItems.forEach((item) => {
		const orbitSize = ORBIT_SIZE * windowWidth;
		const itemX = currentX + (Math.sin(item.orbitX * time + item.orbitOffset) * orbitSize);
		const itemY = currentY + (Math.cos(item.orbitY * time + item.orbitOffset) * orbitSize);
		item.element.style.setProperty('transform', `translate(${itemX * item.factor}px, ${itemY * item.factor}px)`);
	});

	// Disabled rotation for performance reasons.
	// this.rotationItems.forEach((item) => {
	// 	const rotationX = (this.currentY / window.innerHeight - 0.5) * -10;
	// 	const rotationY = (this.currentX / window.innerWidth - 0.5) * 20;
	// 	item.style.transform = `perspective(100vmax) rotateX(${rotationX}deg) rotateY(${rotationY}deg)`;
	// });
}

export default class BlockHoveringObjects extends Component {

	constructor(element: HTMLElement) {
		super(element);

		Array.from(
			this.element.querySelectorAll(`.${CNAME}--object-parallax`),
		).forEach((item: HTMLElement) => {
			parallaxItems.push({
				element: item,
				factor: parseFloat(item.dataset.parallaxFactor),
				orbitX: Math.random() * 0.2 + 0.2,
				orbitY: Math.random() * 0.2 + 0.2,
				orbitOffset: Math.random() * Math.PI,
			});
		});

		/*
		Array.from(
			this.element.querySelectorAll(`.${CNAME}--object-rotation`)
		).forEach((item) => {
			rotationItems.push(item);
		});
		*/

		const shouldRotate = this.element.classList.contains('m-rotate');

		if (shouldRotate) {
			Array.from(this.element.querySelectorAll(`.${CNAME}--object-item`)).forEach((item) => {
				(item as HTMLElement).style.transform = `rotate(${randInt(-20, 20)}deg)`;
			});
		}
	}
}

Component.register(BlockHoveringObjects, 'BlockHoveringObjects');
