var observer: IntersectionObserver;

/**
 * Applies `visible` classes to `animate` elements when visible.
 */
export default class AnimationApplier {
	/**
	 * Watches an element for added child elements, observing them for
	 * intersections.
	 * @param element The element to watch for modifications of.
	 */
	static monitor(element: HTMLElement) {
		// Watch the body for any newly added elements.
		const mutationObserver = new MutationObserver((records) => {
			records.forEach((record) => {
				record.addedNodes.forEach((node) => {
					if (node instanceof HTMLElement) {
						this.scan(node);
					}
				});
			});
		});
		mutationObserver.observe(element, {
			childList: true,
			subtree: true,
		});
	}

	/**
	 * Finds all applicable animatable elements for the provided element.
	 * @param element The element to scan for nodes within.
	 */
	static scan(element: HTMLElement) {
		if (element.classList.contains('animate')) {
			observer.observe(element);
		}
		const elements = element.querySelectorAll('.animate');
		elements.forEach((node) => {
			observer.observe(node);
		});
	}

	/**
	 * Applies the visible class to an animatable element.
	 */
	static apply(entries: IntersectionObserverEntry[]) {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add('visible');
				observer.unobserve(entry.target);
			}
		});
	}
}

observer = new IntersectionObserver(AnimationApplier.apply);
