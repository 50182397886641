import Component from '../../js/_Component';

const CNAME = 'c-card-career-insights-entry';

export default class CardCareerInsightsEntry extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(CardCareerInsightsEntry, 'CardCareerInsightsEntry');
