import Component from '../../js/_Component';

const CNAME = 'c-block-text-centered';

export default class BlockTextCentered extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockTextCentered, 'BlockTextCentered');
