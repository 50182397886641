import Component from '../../js/_Component';

const CNAME = 'c-base-tabs';

export default class BaseTabs extends Component {
	private tabs: HTMLButtonElement[];

	private panels: HTMLDivElement[];

	/** The alternative view to tabs which is only visible on small screens. */
	private select: HTMLSelectElement;

	constructor(element: HTMLElement) {
		super(element);

		this.tabs = Array.from(this.element.querySelectorAll(`.${CNAME}--tab`));
		this.panels = Array.from(this.element.querySelectorAll(`.${CNAME}--panel`));
		this.select = this.element.querySelector(`.${CNAME}--select-element`);

		this.tabs.forEach((tab) => {
			tab.addEventListener('click', this.handleClick.bind(this));
			tab.addEventListener('keydown', this.handleKeydown.bind(this));
		});

		this.select.addEventListener('input', this.handleSelect.bind(this));
	}

	private handleClick(event: MouseEvent): void {
		const tab = (event.target as HTMLElement).closest(`.${CNAME}--tab`) as HTMLButtonElement;
		const index = this.tabs.indexOf(tab);
		this.selectTab(index);
	}

	private handleKeydown(event: KeyboardEvent): void {
		const tab = event.target as HTMLButtonElement;
		const index = this.tabs.indexOf(tab);

		if (event.key === 'ArrowLeft') {
			this.selectTab(index === 0 ? this.tabs.length - 1 : index - 1);
		} else if (event.key === 'ArrowRight') {
			this.selectTab(index === this.tabs.length - 1 ? 0 : index + 1);
		} else if (event.key === 'Home') {
			this.selectTab(0);
		} else if (event.key === 'End') {
			this.selectTab(this.tabs.length - 1);
		}
	}

	private handleSelect(): void {
		const index = this.select.selectedIndex;
		this.selectTab(index);
	}

	private selectTab(index: number): void {
		if (this.tabs[index].getAttribute('aria-selected') === 'true') return;

		this.tabs.forEach((tab, currentIndex) => {
			const shouldSelect = currentIndex === index;
			const panel = this.panels[currentIndex];

			if (shouldSelect) {
				// Hide the new panel until the layout settles.
				panel.style.opacity = '0';
			}

			tab.setAttribute('aria-selected', String(shouldSelect));
			tab.setAttribute('tabindex', shouldSelect ? '0' : '-1');
			panel.hidden = !shouldSelect;

			if (shouldSelect) {
				tab.focus();
				this.deferPanelRevealUntilLayoutSettles(panel);
			}
		});

		// Update the select element to remain in sync when user navigated via tab system.
		this.select.selectedIndex = index;
	}

	/**
	 * This purpose of this function is to wait to reveal the panel until the layout has stopped
	 * shifting. Otherwise, there would be a visible flash once ResizeClasses applies classes, since
	 * the grid system, for example, uses ResizeClasses to determine the widths of columns.
	 *
	 * Aside from calling this function within selectTab(), this functionality also requires each
	 * panel to have a class called 'eq` and optionally a 'transition' property for the opacity.
	 */
	private deferPanelRevealUntilLayoutSettles(panel: HTMLElement): void {
		const promises = [];

		const elementsWithResizeClasses = panel.querySelectorAll('.row, .eq');

		elementsWithResizeClasses.forEach((element) => {
			// Ignore this element if it's not visible (offsetParent returns null when not visible).
			if ((element as HTMLElement).offsetParent === null) return;

			const promise = new Promise((resolve) => {
				element.addEventListener('resizeclasseschanged', () => { resolve(); }, {once: true});
			});

			promises.push(promise);
		});

		// Once every element with resize classes has been updated, reveal the panel.
		Promise.all(promises).then(() => {
			window.requestAnimationFrame(() => {
				window.requestAnimationFrame(() => {
					panel.style.opacity = '1';
				});
			});
		});
	}
}

Component.register(BaseTabs, 'BaseTabs');
