import Component from '../../js/_Component';
import Menu from '../../js/lib/_menu';
import throttle from '../../js/util/_throttle';

const CNAME = 'c-block-header';

export default class BlockHeader extends Component {
	private hamburger: HTMLButtonElement;

	private menu: HTMLElement;

	private logoVisibilityThreshold = 100;

	constructor(element: HTMLElement) {
		super(element);

		this.hamburger = element.querySelector(`.${CNAME}--hamburger`);
		this.menu = element.querySelector(`.${CNAME}--menu`);

		new Menu(this.menu, this.hamburger);

		this.menu.addEventListener('menuopen', () => {
			this.element.classList.add('m-menu-open');
		});

		this.menu.addEventListener('menuclose', () => {
			this.element.classList.remove('m-menu-open');
		});

		this.highlightCurrentPage();

		// Determine whether to show or hide the logo based on scroll position.
		this.toggleLogoVisibility();
		window.addEventListener('scroll', throttle(() => {
			this.toggleLogoVisibility();
		}, 100), {passive: true});
	}

	toggleLogoVisibility() {
		window.pageYOffset < this.logoVisibilityThreshold ? this.showLogo() : this.hideLogo();
	}

	showLogo() {
		if (!this.element.classList.contains('m-hide-logo')) return;

		this.element.classList.remove('m-hide-logo');
	}

	hideLogo() {
		if (this.element.classList.contains('m-hide-logo')) return;

		this.element.classList.add('m-hide-logo');
	}

	highlightCurrentPage() {
		const currentLocation = window.location.pathname;
		const buttonLinks = this.menu.querySelectorAll('a.button');

		buttonLinks.forEach((item) => {
			const path = item.getAttribute('href');

			if (currentLocation.indexOf(path) === 0) {
				item.classList.add('m-current');
			}
		});
	}
}

Component.register(BlockHeader, 'BlockHeader');
