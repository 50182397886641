import Component from '../../js/_Component';

const CNAME = 'c-base-layout-standard';

export default class BaseLayoutJobApplicationForm extends Component {

	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BaseLayoutJobApplicationForm, 'BaseLayoutJobApplicationForm');
