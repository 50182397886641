import Component from '../../js/_Component';

const CNAME = 'base-button-hamburger';

export default class BaseButtonHamburger extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BaseButtonHamburger, 'BaseButtonHamburger');
