import Component from '../../js/_Component';

const CNAME = 'c-page-bio';

export default class PageBio extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PageBio, 'PageBio');
