/**
 * Finds links on the page that are external and adds the appropriate attributes. It also uses a
 * MutationObserver to process links that are added dynamically.
 *
 * target="_blank" - opens the link in a new tab
 * rel="noopener" - prevents the new page from accessing window.opener data
 */
export default class ExternalLinkProcessor {
	/** Internal links that should be treated as external links. */
	private pseudoExternalLinks: string[];

	/** List of filetypes which should be treated as external links. */
	private pseudoExternalFiletypes = ['mp3', 'mp4', 'pdf'];

	/** Creates a new external link processor. */
	constructor(pseudoExternalLinks: string[]) {
		this.pseudoExternalLinks = pseudoExternalLinks;
	}

	/** Whether the link is valid (e.g. not javascript:void(0). */
	static isValidLink(link: HTMLAnchorElement): boolean {
		return link.hostname !== '';
	}

	/** Whether the link is external. */
	static isExternalLink(link: HTMLAnchorElement): boolean {
		return link.hostname !== window.location.hostname;
	}

	/** Whether the link points to a filetype which should be treated as an external resource. */
	isPseudoExternalFiletype(link: HTMLAnchorElement): boolean {
		const pattern = new RegExp(`\.${this.pseudoExternalFiletypes.join('|')}$`);
		return pattern.test(link.pathname);
	}

	/** Whether the link is internal but should be treated as external. */
	isPseudoExternalLink(link: HTMLAnchorElement): boolean {
		return this.pseudoExternalLinks.some((item) => item === link.pathname);
	}

	/** Whether the link should get the attributes. */
	linkShouldGetAttributes(link: HTMLAnchorElement): boolean {
		if (!ExternalLinkProcessor.isValidLink(link)) {
			return false;
		}

		return ExternalLinkProcessor.isExternalLink(link)
			|| this.isPseudoExternalLink(link)
			|| this.isPseudoExternalFiletype(link);
	}

	/** Scans the page for links and processes all that are found. */
	scan(target: HTMLElement): void {
		const links: HTMLAnchorElement[] = Array.from(target.querySelectorAll('a'));
		this.processLinks(links);
	}

	/** Monitors the page for newly created links and processes all that are found. */
	monitor(target: HTMLElement): void {
		const observer = new MutationObserver((records: MutationRecord[]) => {
			let links: HTMLAnchorElement[] = [];

			records.forEach((record: MutationRecord) => {
				Array.from(record.addedNodes).forEach((node: Element) => {
					if (!(node instanceof HTMLElement)) {
						return;
					}

					if (node.tagName === 'A') {
						links.push(node as HTMLAnchorElement);
						return;
					}

					links = [...Array.from(node.querySelectorAll('a'))];
				});
			});

			this.processLinks(links);
		});

		observer.observe(target, {childList: true, subtree: true});
	}

	/** Adds attributes to all external links. */
	processLinks(links: HTMLAnchorElement[]): void {
		links.forEach((link) => {
			if (!this.linkShouldGetAttributes(link)) return;

			link.target = '_blank';
			link.rel = 'noopener';
		});
	}
}
