import Component from '../../js/_Component';

const CNAME = 'c-base-svg';

export default class BaseSVG extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BaseSVG, 'BaseSVG');
