import Component from '../../js/_Component';

const CNAME = 'c-page-media-entry';

export default class PageMediaEntry extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PageMediaEntry, 'PageMediaEntry');
