import Component from '../../js/_Component';

const CNAME = 'c-page-conservation';

export default class PageConservation extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(PageConservation, 'PageConservation');
