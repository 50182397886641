let queued = false;

/**
 * Allows automatic forced sizing of certain SVG elements to offset sizing
 * errors in IE.
 */
export default class SVGResizeFixForIE {
	/**
	 * Attaches the behavior, if necessary.
	 */
	static attach() {
		if (/Trident/.test(navigator.userAgent)) {
			window.addEventListener('resize', this.fixSVGs);
			const observer = new MutationObserver(this.fixSVGs);
			observer.observe(document.body, {
				childList: true,
				subtree: true,
			});
			this.fixSVGs();
		}
	}

	/**
	 * A special function used in IE to fix SVG scaling.
	 */
	static fixSVGs() {
		if (!queued) {
			window.requestAnimationFrame(() => {
				const elements = document.querySelectorAll('svg');

				elements.forEach((element) => {
					if (element.className.baseVal.indexOf('u-svg-auto-width') >= 0) {
						const box = element.viewBox;
						const ratio = box.baseVal.width / box.baseVal.height;
						const style = window.getComputedStyle(element);
						const match = style.height.match(/([\d.]+)(.+)/);
						element.style.width = (parseFloat(match[1]) * ratio) + match[2];
					} else if (element.className.baseVal.indexOf('u-svg-auto-height') >= 0) {
						const box = element.viewBox;
						const ratio = box.baseVal.width / box.baseVal.height;
						const style = window.getComputedStyle(element);
						const match = style.width.match(/([\d.]+)(.+)/);
						element.style.height = (parseFloat(match[1]) / ratio) + match[2];
					}
				});
				queued = false;
			});
			queued = true;
		}
	}
}
