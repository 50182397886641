import Component from '../../js/_Component';

const CNAME = 'c-block-footer-compact';

export default class BlockFooterCompact extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockFooterCompact, 'BlockFooterCompact');
