// Adds `new Event()` and `new CustomEvent()` support for IE 11.

interface Window {
	Event: Function,
}

if (typeof Event === 'object') {

	let oldEvent = window.Event;

	window.Event = function (name, options = {}) {
		let event = document.createEvent('Event');
		event.initEvent(name, !!options.bubbles, !!options.cancelable);
		return event;
	} as any;

	window.Event.prototype = oldEvent;
}

if (typeof CustomEvent === 'object') {
	let polyfillCustomEvent = function(event, params) {
		params = params || {bubbles: false, cancelable: false, detail: undefined};
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}
	polyfillCustomEvent.prototype = (window as any).CustomEvent.prototype;
	(window as any).CustomEvent = polyfillCustomEvent;
}
