// Adds `DocumentFragment.firstElementChild`, `DocumentFragment.lastElementChild`, and `DocumentFragment.children` support for IE 11.

if (!DocumentFragment.prototype.hasOwnProperty('firstElementChild')) {
	Object.defineProperty(DocumentFragment.prototype, 'firstElementChild', {
		get: function () {
			var node, nodes = this.childNodes, i = 0;
			while (node = nodes[i++]) {
				if (node.nodeType === 1) {
					return node;
				}
			}
			return null;
		}
	});
}

if (!DocumentFragment.prototype.hasOwnProperty('lastElementChild')) {
	Object.defineProperty(DocumentFragment.prototype, 'lastElementChild', {
		get: function () {
			var node, nodes = this.childNodes, i = nodes.length - 1;
			while (node = nodes[i--]) {
				if (node.nodeType === 1) {
					return node;
				}
			}
			return null;
		}
	});
}

if (!DocumentFragment.prototype.hasOwnProperty('children')) {
	Object.defineProperty(DocumentFragment.prototype, 'children', {
		get: function () {
			var i = 0, node, nodes = this.childNodes, children = [];
			while (node = nodes[i++]) {
				if (node.nodeType === 1) {
					children.push(node);
				}
			}
			return children;
		}
	});
}