import Component from '../../js/_Component';

const CNAME = 'c-block-page-controls';

export default class BlockPageControls extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockPageControls, 'BlockPageControls');
