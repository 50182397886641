import Component from '../../js/_Component';

const CNAME = 'c-page-careers';

export default class PageCareers extends Component {

	private currentRequest: Promise<Response> = null;

	private lastRequestURL: URL = null;
	private lastRequestPage: number = 1;

	private selectCategory: HTMLSelectElement = null;
	private selectJobType: HTMLSelectElement = null;
	private selectLocation: HTMLSelectElement = null;
	private entriesElement: Element = null;

	constructor(element: HTMLElement) {
		super(element);

		let currentUrl = new URL(window.location.toString());
		let searchButton = element.querySelector(`.${CNAME}--submit-button button`);
		let resetButton = element.querySelector(`.${CNAME}--reset-button span`);

		this.selectCategory = element.querySelector(`select[name="category"]`);
		this.selectJobType = element.querySelector(`select[name="jobType"]`);
		this.selectLocation = element.querySelector(`select[name="location"]`);

		this.entriesElement = element.querySelector(`.${CNAME}--entries`);

		// Update the selects to match any search parameters from the URL.
		if (currentUrl.searchParams.has('category') && this.selectCategory) {
			this.selectCategory.value = currentUrl.searchParams.get('category');
			document.getElementById('filters').scrollIntoView();
		}
		if (currentUrl.searchParams.has('jobType') && this.selectJobType) {
			this.selectJobType.value = currentUrl.searchParams.get('jobType');
			document.getElementById('filters').scrollIntoView();
		}
		if (currentUrl.searchParams.has('location') && this.selectLocation) {
			this.selectLocation.value = currentUrl.searchParams.get('location');
			document.getElementById('filters').scrollIntoView();
		}

		/*
		// Update search when 'submit' button is clicked.
		searchButton.addEventListener('click', () => {
			if (this.currentRequest) return;
			// Start with a base URL for the current page, stripping out any page number.
			let url = new URL('careers-query', `${window.location.protocol}//${window.location.host}`);
			if (this.selectCategory) {
				url.searchParams.set('category', this.selectCategory.value);
			}
			if (this.selectJobType) {
				url.searchParams.set('jobType', this.selectJobType.value);
			}
			if (this.selectLocation) {
				url.searchParams.set('location', this.selectLocation.value);
			}
			this.clearResults();
			this.fetchResults(url, 1);
		});

		// Reset search params when 'reset' button is clicked.
		resetButton.addEventListener('click', () => {
			this.resetResults();
		});

		// Load the next page when the 'view more' button is clicked.
		this.entriesElement.addEventListener('click', e => {
			if (this.currentRequest) return;
			let clickedElement: Element = e.target as Element;
			if (clickedElement.closest(`.${CNAME}--more-button`) && clickedElement.closest(`button`)) {
				clickedElement.closest(`.${CNAME}--more-button`).remove();
				this.fetchResults(this.lastRequestURL, this.lastRequestPage + 1);
			}
		});
		*/
		
		//this.resetResults();

	}

	resetResults() {
		if (this.currentRequest) return;
		// Start with a base URL for the current page, stripping out any page number.
		let url = new URL('careers-query', `${window.location.protocol}//${window.location.host}`);
		if (this.selectCategory) {
			this.selectCategory.value = '';
		}
		if (this.selectJobType) {
			this.selectJobType.value = '';
		}
		if (this.selectLocation) {
			this.selectLocation.value = '';
		}
		this.clearResults();
		this.fetchResults(url, 1);
	}

	clearResults() {
		this.entriesElement.innerHTML = '';
	}

	fetchResults(url: URL, page: number) {
		this.lastRequestURL = url;
		this.lastRequestPage = page;
		let pageUrl = new URL(url.toString());
		pageUrl.pathname += '/p' + page;
		this.currentRequest = fetch(pageUrl.toString());
		this.currentRequest.then(response => {
			this.currentRequest = null;
			response.text().then(content => {
				this.entriesElement.insertAdjacentHTML('beforeend', content);
			});
		});
	}
}

//Component.register(PageCareers, 'PageCareers');
