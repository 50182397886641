/// <reference path="./_IComponent.ts" />

import EventEmitter from './util/_EventEmitter';

const emitter = new (EventEmitter(class Dummy {}))();

const components: {[key: string]: {new(element: HTMLElement): Component}} = {};

const componentNameLookup: Map<Function, string> = new Map();

/**
 * Base component class that tracks a global registry of components.
 * Each component created should call this class' `register` function along with
 * the constructor of the component.
 */
export default class Component {
	protected element: HTMLElement;

	constructor(element: HTMLElement) {
		// Assign the component to the element via an expando property.
		(element as ComponentElement).component_ = this;
		// Store the element within this component.
		this.element = element;
	}

	/**
	 * Registers a newly available component with the system.
	 * @param component The component constructor to register.
	 */
	static register(component: {new(element: HTMLElement): Component}, name: string) {
		// Convert all capitalized segments into dash-delimited
		let componentClassName = name.replace(/([A-Z][a-z]+|[0-9]+|[A-Z]+(?=[A-Z0-9]))/g, '$&-');
		// Remove last character (extra dash).
		componentClassName = componentClassName.substring(0, componentClassName.length - 1);
		// Lower case only.
		componentClassName = componentClassName.toLowerCase();
		// Store original name keyed to component constructor.
		componentNameLookup.set(component, name);
		// Store component keyed to css class name.
		components[componentClassName] = component;
		emitter.raise('registered', component);
	}

	/**
	 * Attaches a listener to the global component system.
	 * @param name The name of the event to listen for.
	 * @param func The function to call when the event is raised.
	 */
	static on(name: string, func: Function) {
		emitter.on(name, func);
	}

	/**
	 * Retrieves an array of each component.
	 */
	static getAllComponents() {
		return Object.values(components);
	}

	/**
	 * Monitors the provided element for element additions, and initializes any
	 * new components added.
	 * @param element The element to monitor for changes.
	 */
	static monitor(element: HTMLElement) {
		const observer = new MutationObserver((changes) => {
			changes.forEach((change) => {
				if (change.addedNodes) {
					change.addedNodes.forEach((node) => {
						if (node instanceof HTMLElement) {
							this.scan(node);
							this.initialize(node);
						}
					});
				}
			});
		});
		observer.observe(element, {
			childList: true,
			subtree: true,
		});
	}

	/**
	 * Scans the whole child tree of the provided element to find components and
	 * initializes any it finds.
	 * @param element The element to scan for inner components of.
	 */
	static scan(element: HTMLElement) {
		// Find all child components.
		const nodes = element.querySelectorAll('[class^="c-"], [class*=" c-"]');
		// Initialize in reverse order, so inner components are guaranteed to initialize before
		// outer components.
		Array.from(nodes).reverse().forEach((node) => {
			if (node instanceof HTMLElement) {
				this.initialize(node);
			}
		});
	}

	/**
	 * Attempts to find a valid component class name on the provided element and
	 * initializes it with the relevant component class(es).
	 * @param element The element to initialize.
	 */
	static initialize(element: HTMLElement) {
		element.classList.forEach((className) => {
			let initialized = false;
			if (className.substring(0, 2) === 'c-') {
				if (initialized) {
					console.warn('Cannot initialize component element more than once.');
					return;
				}
				// Find a registered component with this class name.
				const component = components[className.substring(2)];
				// If a component is registered with the name.
				if (component) {
					// Initialize the component by creating the component with the element.
					/* eslint-disable-next-line new-cap */
					new component(element);
					// Mark as initialized to prevent duplicate initialization.
					initialized = true;
				}
			}
		});
	}

	/**
	 * Retrieves a component object that was initialized for an Element.
	 * @param element The element to retrieve the component object for.
	 */
	static getComponent<T extends Component>(element: HTMLElement): T {
		return (element as ComponentElement).component_ as T;
	}

	static getName(component: {new(element: HTMLElement): Component}) {
		return componentNameLookup.get(component);
	}
}

interface ComponentElement extends HTMLElement {
	component_: Component;
}

window['ComponentClass'] = Component;
