import Component from '../../js/_Component';

const CNAME = 'c-block-back-to-overview-button';

export default class BlockBackToOverviewButton extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockBackToOverviewButton, 'BlockBackToOverviewButton');
