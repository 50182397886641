/**
 * Scripts that are already set to be loaded, and their associated promises..
 */
const loadedScripts: Map<string, Promise<unknown>> = new Map();

/**
 * This begins loading scripts and provides a promise back that will resolve
 * once all provided scripts are loaded.
 *
 * This can be used to load google map scripts or other secondary APIs that
 * should only be loaded on particular pages.
 *
 * This tries to be resilient to multiple requests for the same scripts in
 * different configurations, making sure each request order is fulfilled as best
 * as possible, although ultimately the first requested order is the one that
 * will influence the loading order of scripts.
 *
 * @param scripts An array of scripts that will be added to the document. If
 * multiple scripts are provided, they will be loaded in order, each waiting
 * for the previous to finish before starting to load. To load multiple scripts
 * without regard for fetch order, simply call LoadScripts more than once.
 */
export default function LoadScripts(...scripts: string[]): Promise<unknown> {
	// Start with a resolved promise to chain from.
	let sequencePromise: Promise<unknown> = Promise.resolve();

	scripts.forEach((script) => {
		// Try to look up an outstanding promise for the provided script.
		let scriptPromise = loadedScripts.get(script);

		// Script is already queued/loaded.
		if (scriptPromise) {
			// Roll the provided script promise into the existing promise.
			// This way all previous ones have to finish before the next is
			// resolved, even if they have been queued in a different order by
			// other requests.
			sequencePromise = Promise.all([sequencePromise, scriptPromise]);
		// Script is not queued/loaded.
		} else {
			// Set the script to load after the existing sequence, and start a
			// new sequence with its promise.
			scriptPromise = sequencePromise.then(() => {
				return new Promise((resolve) => {
					const scriptElement = document.createElement('script');
					scriptElement.type = 'text/javascript';
					scriptElement.src = script;
					scriptElement.onload = resolve;
					document.body.appendChild(scriptElement);
				});
			});
			// Store the promise, so that further requests will use the same
			// promise for this script loading.
			loadedScripts.set(script, scriptPromise);
			sequencePromise = scriptPromise;
		}
	});

	// Finally, after all script promises have been resolved/started, return the
	// constructed sequence of promises, which will resolve once all requested
	// scripts have been loaded.
	return sequencePromise;
}
