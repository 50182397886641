import Component from '../../js/_Component';

const CNAME = 'c-block-image';

export default class BlockImage extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockImage, 'BlockImage');
