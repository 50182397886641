import Component from '../../js/_Component';

const CNAME = 'c-block-button';

// Dummy component, its template routes to other components instead.
// It has this script for the sole purpose of being able to be registered in the
// component library.
export default class BlockButton extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BlockButton, 'BlockButton');
