import Component from '../../js/_Component';

const CNAME = 'c-base-background-image';

export default class BaseBackgroundImage extends Component {
	constructor(element: HTMLElement) {
		super(element);
	}
}

Component.register(BaseBackgroundImage, 'BaseBackgroundImage');
