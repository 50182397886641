/**
 * Simple throttle function to reduce the frequency of calls to a function.
 * @param {Function} callback - The function to throttle.
 * @param {Number} delay - The time (in milliseconds) to wait between calls.
 */
export default function throttle(callback, delay) {
	let timeout = null;

	return function call(...args) {
		const next = () => {
			callback.apply(this, args);
			timeout = null;
		};

		if (!timeout) {
			timeout = setTimeout(next, delay);
		}
	};
}
